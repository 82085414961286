import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Navbar } from "react-bootstrap";
import { useRouter } from "next/router";
import { AppContext } from "../../context/state";
import { authGetRequest } from "../../utils/authGetRequest";
import { useCookies } from "react-cookie";
import { leagueData } from "../header/Header";
import dynamic from "next/dynamic";
import Utils from "../../utils/Utils";
import ToggleButton from "../button/ToggleButton";
const GameCard = dynamic(() => import("./GameCard/GameCard"), { ssr: true });
import Select from "react-select";
const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "30px",
    maxHeight: "30px", // Adjust the height as needed
    minHeight: "30px", // Adjust the height as needed
    backgroundColor: "white", // Change the background color
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0px",
    margin: "0px", // Adjust the padding of the dropdown indicator
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: "0",
    padding: "0px",
    margin: "0px", // Adjust the top margin of the dropdown menu
  }),
};
const leagues = Object.keys(leagueData);
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const LiveLinksHeader = () => {
  const [user, setUser] = useState(null);
  const router = useRouter();
  const leagueFromUrl = router.query?.league === undefined ? "ncaa" : router.query?.league;
  const [authToken, setAuthToken] = useState(null);
  const [maxCardHeight, setMaxCardHeight] = useState(0);
  const [setCookie] = useCookies(["token_access"]);
  const { store, setStore } = useContext(AppContext);
  const { showLiveLinkHeader = true } = store;
  const navbarRef = useRef();
  const [isShown, setIsShown] = useState(showLiveLinkHeader);
  const isPremiumPlan = Utils.hasPremiumPlan(user);
  const isStandardPlan = Utils.hasStandardPlan(user);
  const isTrader = Utils.isTrader(user);
  const [liveGameType, setLiveGameType] = useState(leagueFromUrl === "ncaa" ? "college_mens" : leagueFromUrl);
  const [games, setGames] = useState([]);
  const [liveGames, setLiveGames] = useState([]);
  const [nbaGames, setNbaGames] = useState([]);
  const [wnbaGames, setWnbaGames] = useState([]);
  const [ncaaGames, setNcaaGames] = useState([]);
  const [gamesToShow, setGamesToShow] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [dateFilter, setDateFilter] = useState([{ value: "All", label: "All" }]);
  const [selectedDate, setSelectedDate] = useState();
  const todaysDate = new Date();
  // Ocotober 15th to June 30th NBA
  // November 1st to April 10th NCAAM
  // May 1st to October 15th WWNBA
  const isNBA =
    todaysDate >= new Date(todaysDate.getFullYear() - 1, 9, 15) &&
    todaysDate <= new Date(todaysDate.getFullYear() + 1, 5, 30);
  const isNCAAM =
    todaysDate >= new Date(todaysDate.getFullYear() - 1, 10, 1) &&
    todaysDate <= new Date(todaysDate.getFullYear() + 1, 3, 10);
  const isWNBA =
    todaysDate >= new Date(todaysDate.getFullYear(), 4, 1) && todaysDate <= new Date(todaysDate.getFullYear(), 9, 15);

  let currentDate = "";
  const arrayOfCards = gamesToShow.map((game, idx) => {
    if (idx === 0) {
      currentDate = "";
    }
    const formattedDate = formatDate(new Date(game.game_time_nyc).getTime());

    const milliseconds = new Date(game.game_time_nyc).getTime();
    const date = new Date(milliseconds);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    // Convert hours from 24-hour format to 12-hour format
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

    // Pad minutes with leading zero if needed
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const time = `${formattedHours}:${formattedMinutes} ${ampm}`;
    const data = {
      game: game,
      maxCardHeight: maxCardHeight,
      formattedDate: formattedDate,
      time: time,
      leagueFromUrl: leagueFromUrl,
      store: store,
      idx: idx,
      isPremiumPlan: isPremiumPlan,
      isStandardPlan: isStandardPlan,
    };

    // if (game.status === "final") return !game.game_link ? null : <GameCard data={data} key={idx} />;
    if (game.filterDate !== currentDate && game.status !== "final") {
      currentDate = game.filterDate;
      let dateArr = game.filterDate.split(" ");

      return (
        <Fragment key={"frag" + idx}>
          <div className=" text-center p-2" style={{ width: "5rem" }} key={"date-filter" + idx}>
            <h6>{dateArr[0]}</h6>
            <div>{dateArr[1]}</div>
          </div>
          <GameCard data={data} key={"game-card" + idx} />
        </Fragment>
      );
    } else if (game.status === "final") return null;
    else return <GameCard data={data} key={"game-card-else" + idx} />;
  });

  function formatDate(dateString) {
    const date = new Date(dateString);
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayOfWeek = daysOfWeek[date.getDay()];

    const monthName = monthNames[date.getMonth()];
    const dayOfMonth = date.getDate();

    function getDayOfMonthSuffix(day) {
      if (day >= 11 && day <= 13) {
        return "th";
      }
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    const dayOfMonthSuffix = getDayOfMonthSuffix(dayOfMonth);
    return `${dayOfWeek} ${monthName} ${dayOfMonth}${dayOfMonthSuffix}`;
  }

  useEffect(() => {
    setAuthToken(document.cookie.split("token_access=").pop().split(";").shift());
    let maxHeight = 176;
    navbarRef.current.childNodes.forEach((child) => {
      if (child.nodeType === Node.ELEMENT_NODE) {
        const height = child.offsetHeight;
        if (height > maxHeight) {
          maxHeight = height;
        }
      }
    });
    setMaxCardHeight(maxHeight);
  }, []);

  useEffect(() => {
    const value = localStorage.getItem("user");
    if (value) {
      setUser(JSON.parse(value));
    }
  }, [authToken]);

  const sortGames = (games) => {
    const now = new Date();
    return games.sort((a, b) => {
      if (a.status === "live" && b.status !== "live") {
        return -1;
      }
      if (a.status !== "live" && b.status === "live") {
        return 1;
      }
      if (a.status === "live" && b.status === "live") {
        const dateA = new Date(a.game_time_nyc);
        const dateB = new Date(b.game_time_nyc);
        return dateA - dateB;
      }

      if (a.status === "final" && b.status !== "final") {
        return 1;
      }
      if (a.status !== "final" && b.status === "final") {
        return -1;
      }
      if (a.status === "final" && b.status === "final") {
        const dateA = new Date(a.game_time_nyc);
        const dateB = new Date(b.game_time_nyc);
        return dateA - dateB;
      }

      const dateA = new Date(a.game_time_nyc);
      const dateB = new Date(b.game_time_nyc);
      // if (dateA > now && dateB <= now) {
      //   return -1;
      // }
      // if (dateA <= now && dateB > now) {
      //   return 1;
      // }
      return dateA - dateB;
    });
    // return games.sort((a, b) => {
    //   const dateA = new Date(a.game_time_nyc);
    //   const dateB = new Date(b.game_time_nyc);

    //   if (a.status === "live") return -1;
    //   if (b.status === "live") return 1;

    //   if (dateA > now && b.status !== "live") return -1;
    //   if (dateB > now && a.status !== "live") return 1;

    //   return dateA - dateB;
    // });
  };
  const leagueArray = ["college_mens", "nba", "college_womens", "wnba"];
  if (isTrader) {
    leagueArray.push("japan_b1");
  }
  useEffect(() => {
    if (authToken) {
      Promise.all(
        leagueArray.map((league) => authGetRequest(`/api/${league}/live-games${user ? "" : "/unprotected"}`, authToken))
      )
        .then((responseArray) => {
          const combinedData = responseArray.map((response) => {
            if (!response) {
              return null;
            }
            return {
              leagueType: response.config.url.match(/\/api\/([^\/]+)\/live-games/)[1],
              data: response.data,
            };
          });
          return combinedData;
        })
        .then(async (combinedData) => {
          const headerLeagueGames = {
            college_mens: [],
            wnba: [],
            nba: [],
            college_womens: [],
          };

          combinedData.forEach((league) => {
            if (!league) return;
            const { leagueType, data } = league;
            headerLeagueGames[leagueType] = data.data ? data.data.map((game) => ({ ...game, leagueType })) : [];
          });
          Object.keys(headerLeagueGames).forEach((leagueType) => {
            headerLeagueGames[leagueType] = sortGames(headerLeagueGames[leagueType]);
          });
          combinedData = combinedData.filter((league) => league);
          const allGames =
            combinedData.flatMap(({ leagueType, data }) => {
              if (!data.data) {
                return [];
              }
              return data.data.map((game) => ({ ...game, leagueType }));
            }) || [];

          const dataWithFilterDate = allGames.map((game) => {
            const formattedDate = formatDate(new Date(game.game_time_nyc).getTime());
            let dateArr = formattedDate.split(" ");
            const shortDay = dateArr[0].substring(0, 3).toUpperCase();
            const monthNumber = monthNames.indexOf(dateArr[1]) + 1;
            const shortDate = monthNumber + "/" + dateArr[2].substring(0, dateArr[2].length - 2);
            return { ...game, filterDate: shortDay + " " + shortDate };
          });

          const sortedGames = sortGames(dataWithFilterDate);

          const liveGames = sortedGames.filter((game) => game.status === "live");
          const nbaGames = sortedGames.filter((game) => game.leagueType === "nba");
          const wnbaGames = sortedGames.filter((game) => game.leagueType === "wnba");
          const ncaaGames = sortedGames.filter((game) => game.leagueType === "college_mens");
          localStorage.setItem("HeaderLeagueGames", JSON.stringify(headerLeagueGames));
          setLiveGames(liveGames);
          setNbaGames(nbaGames);
          setWnbaGames(wnbaGames);
          setNcaaGames(ncaaGames);
          setStore({ ...store, headerLeagueGames: headerLeagueGames });
          setGames(sortedGames);

          const filterDateArray = sortedGames.map((game) => {
            if (game.status === "final") return null;
            return game.filterDate;
          });
          const uniqueFilterDateArray = [...new Set(filterDateArray)].map((date) => {
            return { value: date, label: date };
          });
          setDateFilter([{ value: "All", label: "All" }, ...uniqueFilterDateArray]);
          if (leagueFromUrl === "ncaa" && ncaaGames.length > 0) {
            setLiveGameType("college_mens");
          } else if (leagueFromUrl === "wnba" && wnbaGames.length > 0) {
            setLiveGameType(leagueFromUrl);
          } else if (leagueFromUrl === "nba" && nbaGames.length > 0) {
            setLiveGameType(leagueFromUrl);
          } else {
            setLiveGameType("all");
          }
          setTimeout(() => {
            setIsDataLoaded(true);
          }, 800);
        })
        .catch(async (err) => {
          if (err.message.includes("401")) {
            try {
              const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL_API_URL}/api/refresh`, {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${authToken}`,
                },
                body: authToken,
              });
              const response = await res.json();

              if (response.access_token) {
                setCookie("token_access", response.access_token);
                setAuthToken(response.access_token);
              } else {
                console.error("Error refreshing token", response);
              }
            } catch (error) {
              console.error("Error during token refresh:", error);
            }
          } else {
            console.error("API request error:", err);
          }
        });
    }
  }, [authToken, user]);

  useEffect(() => {
    let gamesToShow = [];
    if (!liveGameType) {
      setLiveGameType(store.leagueType);
    }
    if (liveGameType === "live") {
      gamesToShow.push(...liveGames);
    } else if (liveGameType === "wnba") {
      gamesToShow.push(...wnbaGames);
    } else if (liveGameType === "nba") {
      gamesToShow.push(...nbaGames);
    } else if (liveGameType === "college_mens") {
      gamesToShow.push(...ncaaGames);
    } else {
      gamesToShow.push(...games);
    }
    if (selectedDate && selectedDate !== "All") {
      gamesToShow = gamesToShow.filter((game) => game.filterDate === selectedDate);
    }
    gamesToShow = gamesToShow.filter((game) => game.status !== "final");
    setGamesToShow(gamesToShow);
  }, [games, liveGames, nbaGames, wnbaGames, ncaaGames, liveGameType, selectedDate]);
  const handleHideShow = () => {
    setStore({ ...store, showLiveLinkHeader: !isShown });
    setIsShown(!isShown);
  };
  return (
    <div style={{ position: "relative" }}>
      <Navbar id="live-link-header" className="live-header-navbar" ref={navbarRef} bg="light" variant="light">
        {isDataLoaded && isShown && (
          <>
            <div className="live-header-league-menu">
              {/* <div
                onClick={() => setLiveGameType("live")}
                className={`live-header-league-menu-item ${liveGameType === "live" ? "active" : ""}`}
              >
                Live
              </div> */}
              <div
                onClick={() => setLiveGameType("all")}
                className={`live-header-league-menu-item ${liveGameType === "all" ? "active" : ""}`}
              >
                All
              </div>
              {isNBA && (
                <div
                  onClick={() => setLiveGameType("nba")}
                  className={`live-header-league-menu-item ${liveGameType === "nba" ? "active" : ""}`}
                >
                  NBA
                </div>
              )}
              {isWNBA && (
                <div
                  onClick={() => setLiveGameType("wnba")}
                  className={`live-header-league-menu-item ${liveGameType === "wnba" ? "active" : ""}`}
                >
                  WNBA
                </div>
              )}
              {isNCAAM && (
                <div
                  onClick={() => setLiveGameType("college_mens")}
                  className={`live-header-league-menu-item ${liveGameType === "college_mens" ? "active" : ""}`}
                >
                  NCAA
                </div>
              )}
            </div>
            {arrayOfCards}
            {arrayOfCards.length === 0 && (
              <h4 className="w-100 text-center">
                No{" "}
                {liveGameType === "all" ? "" : liveGameType === "college_mens" ? "NCAA" : liveGameType?.toUpperCase()}{" "}
                games available!
                {/* Enjoy the WNBA All Star Break and see you back on 08/15 🏀 */}
              </h4>
            )}
          </>
        )}
      </Navbar>
      {isDataLoaded && (
        <>
          <span className="live-header-hide-show-btn d-flex justify-content-center">
            <span>Hide Scores</span>
            <ToggleButton isShown={!isShown} handleHideShow={handleHideShow} />
          </span>
          {isShown && (
            <span className="live-header-hide-show-btn select-date-filter-dropdown d-flex justify-content-center">
              <Select
                instanceId="select-filter-date"
                id={"select-filter-date"}
                options={dateFilter}
                placeholder="All conferences"
                value={{
                  value: selectedDate ? selectedDate : "All",
                  label: selectedDate ? selectedDate : "Select Date",
                }}
                styles={customStyles}
                onChange={(e) => {
                  setSelectedDate(e.value);
                }}
              />
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default LiveLinksHeader;
